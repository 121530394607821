import { FLATICON_ICONS } from "./icons"
import { Link } from "gatsby"
import React from "react"

export const FEATURE_CARDS_CONFIG = {
  INDUSTRIES: [
    {
      heading: 'Ecommerce',
      subheading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna',
      displayAnimatedCircles: false,
      icon: FLATICON_ICONS.EMAIL_MARKETING,
      noCta: true,
      ctaButton: (
        <Link to="/single-services" className="learn-more-btn">
          <i className="left-icon flaticon-next-button"></i>
          Learn More <i className="right-icon flaticon-next-button"></i>
        </Link>
      ),
    },
    {
      heading: 'Software companies',
      subheading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna',
      displayAnimatedCircles: false,
      icon: FLATICON_ICONS.SPEED,
      noCta: true,
      ctaButton: (
        <Link to="/single-services" className="learn-more-btn">
          <i className="left-icon flaticon-next-button"></i>
          Learn More <i className="right-icon flaticon-next-button"></i>
        </Link>
      ),
    },
    {
      heading: 'Education',
      subheading: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna',
      displayAnimatedCircles: false,
      icon: FLATICON_ICONS.RESEARCH,
      noCta: true,
      ctaButton: (
        <Link to="/single-services" className="learn-more-btn">
          <i className="left-icon flaticon-next-button"></i>
          Learn More <i className="right-icon flaticon-next-button"></i>
        </Link>
      ),
    }
  ],
  USE_CASES: [
    {
      heading: 'Onboarding / walkthrough',
      subheading: `
        Improve your onboarding process by covering more content and allowing your 
        attendees / users to interact with you via Multiplayer mode as if you're next to each other.
      `,
      displayAnimatedCircles: false,
      icon: FLATICON_ICONS.EMAIL_MARKETING,
      noCta: true,
      ctaButton: (
        <Link to="/single-services" className="learn-more-btn">
          <i className="left-icon flaticon-next-button"></i>
          Learn More <i className="right-icon flaticon-next-button"></i>
        </Link>
      ),
    },
    {
      heading: 'Support',
      subheading: `
        Whenever your customer faces a problem, you need context to resolve it. WIth Stickly, you can seamlessly access your users\' screens and
        solve the issues without any frustrating back-and-forth communication.
        `,
      displayAnimatedCircles: false,
      icon: FLATICON_ICONS.SPEED,
      noCta: true,
      ctaButton: (
        <Link to="/single-services" className="learn-more-btn">
          <i className="left-icon flaticon-next-button"></i>
          Learn More <i className="right-icon flaticon-next-button"></i>
        </Link>
      ),
    },
    {
      heading: 'User retention',
      subheading: `
        Retain your users before they leave your website by accessing real-time insights.
        Instantly jump in to help your users and never lose a single one again because of frustration.
      `,
      displayAnimatedCircles: false,
      icon: FLATICON_ICONS.RESEARCH,
      noCta: true,
      ctaButton: (
        <Link to="/single-services" className="learn-more-btn">
          <i className="left-icon flaticon-next-button"></i>
          Learn More <i className="right-icon flaticon-next-button"></i>
        </Link>
      ),
    }
  ],
  BENEFITS: [
    {
      heading: 'Onboarding / walkthrough',
      subheading: `
        Improve your onboarding process by covering more content and allowing your 
        attendees / users to interact with you via Multiplayer mode as if you're next to each other.
      `,
      displayAnimatedCircles: false,
      icon: FLATICON_ICONS.EMAIL_MARKETING,
      noCta: true,
      ctaButton: (
        <Link to="/single-services" className="learn-more-btn">
          <i className="left-icon flaticon-next-button"></i>
          Learn More <i className="right-icon flaticon-next-button"></i>
        </Link>
      ),
    },
    {
      heading: 'Support',
      subheading: `
        Whenever your customer faces a problem, you need context to resolve it. WIth Stickly, you can seamlessly access your users\' screens and
        solve the issues without any frustrating back-and-forth communication.
        `,
      displayAnimatedCircles: false,
      icon: FLATICON_ICONS.SPEED,
      noCta: true,
      ctaButton: (
        <Link to="/single-services" className="learn-more-btn">
          <i className="left-icon flaticon-next-button"></i>
          Learn More <i className="right-icon flaticon-next-button"></i>
        </Link>
      ),
    },
    {
      heading: 'User retention',
      subheading: `
        Retain your users before they leave your website by accessing real-time insights.
        Instantly jump in to help your users and never lose a single one again because of frustration.
      `,
      displayAnimatedCircles: false,
      icon: FLATICON_ICONS.RESEARCH,
      noCta: true,
      ctaButton: (
        <Link to="/single-services" className="learn-more-btn">
          <i className="left-icon flaticon-next-button"></i>
          Learn More <i className="right-icon flaticon-next-button"></i>
        </Link>
      ),
    }
  ]
}
