import React from 'react';
import PropTypes from 'prop-types';
import { Link } from "gatsby"
import cx from 'classnames';

import './Button.scss';

const BUTTON_TYPES = {
  PRIMARY: 'primary',
  DEFAULT: 'default',
  TRANSPARENT: 'transparent',
}

const resolveClassNameByType = (type) => {
  switch(type) {
    case BUTTON_TYPES.TRANSPARENT:
      return 'transparent';
    case BUTTON_TYPES.PRIMARY:
      return 'primary';
    case BUTTON_TYPES.DEFAULT:
      return 'default-btn';
    default:
      return 'default-btn';
  }
}

export const Button = ({
  types = ['default'],
  isLink = true,
  link = null,
  label = null,
  children = null,
  withBtnBoxWrapper = false,
  openInNewTab = false,
                         onClick = null,
  isExternalLink,
  mailToLink = false,
  inlineBlock = false,
}) => {
  const finalClassName = `default-btn ${types.join(' ')}`
  // const finalClassName = `default-btn ${resolveClassNameByType(type)}`;

  const handleClick = () => {
    if (onClick) onClick();
  }

  const renderLink = () => (
    onClick || mailToLink || isExternalLink
      ? (
        <a href={isLink || isExternalLink ? link : null}
           target={isExternalLink || openInNewTab ? '_blank' : null}
           className={finalClassName}
           onClick={handleClick}
        >
          {children || label}
        </a>
      )
      : (
        <Link
          to={isLink ? link : '#'}
          className={finalClassName}
        >
          {children || label}
        </Link>
      )
  );

  if (isLink) {
    if (withBtnBoxWrapper) {
      return (
        <div className="btn-box">
          {renderLink()}
        </div>
      );
    }
    return renderLink();
  }
  return renderLink();
}

Button.propTypes = {
  onClick: PropTypes.func,
  types: PropTypes.arrayOf(PropTypes.string),
  isLink: PropTypes.bool,
  link: PropTypes.string,
  label: PropTypes.string,
  children: PropTypes.node,
  withBtnBoxWrapper: PropTypes.bool,
  openInNewTab: PropTypes.bool,
  mailToLink: PropTypes.bool,
  isExternalLink: PropTypes.bool,
}
