import React from 'react';
import PropTypes from 'prop-types';
import { ObjectUtils } from "../../utils/objectUtils"

const SocialLink = ({ platform, url, openInNewTab }) => (
  <li>
    <a href={url} className="d-block" target={openInNewTab ? '_blank' : null}>
      <i className={`bx bxl-${platform.toLowerCase()}`} />
    </a>
  </li>
);

export const SocialLinks = ({ socialLinks }) => {
  const activeSocialLinks = ObjectUtils.removeNilValues(socialLinks);
  return (
    <ul className="social-link">
      {Object
        .keys(activeSocialLinks)
        .map((platformName, index) => {
          const key = platformName + '-' + index;
          return (
            <SocialLink
              key={key}
              openInNewTab={true}
              platform={platformName}
              url={activeSocialLinks[platformName]}
            />
          );
        })
      }
    </ul>
  );
}

SocialLinks.propTypes = {
  socialLinks: PropTypes.object.isRequired,
}