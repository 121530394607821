export class ObjectUtils {
  static removeNilValues = (obj) => {
    let newObj = {};
    Object.keys(obj).forEach((key) => {
      const value = obj[key];
      if (value) {
        newObj[key] = value;
      }
    });
    return newObj;
  }

  static mapToArrayWithKey = (obj, newKeyNameForArrayItem) => (
    Object.keys(obj).map((key) => ({
      [newKeyNameForArrayItem]: key,
      ...obj[key],
    }))
  )

  static prefixAllValuesWith(obj, prefix) {
    let newObj = {};
    Object.keys(obj).forEach((key) => {
      newObj[key] = prefix + obj[key];
    });
    return newObj;
  }

  static suffixAllValuesWith(obj, suffix) {
    let newObj = {};
    Object.keys(obj).forEach((key) => {
      newObj[key] = obj[key] + suffix;
    });
    return newObj;
  }

}
