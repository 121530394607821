import React from 'react';
import PropTypes from "prop-types"
import cx from 'classnames';
import _ from 'lodash';
import { COMPANY_DETAILS } from "../../config"

const CompanyMailItem = () => (
  <li>
    <i className="bx bx-envelope"></i>
    <a href={'mailto:' + COMPANY_DETAILS.EMAILS.HELLO}>
      {COMPANY_DETAILS.CONTACT_INFO.PRIMARY_EMAIL}
    </a>
  </li>
)

const CompanyPhoneContact = () => (
  <li>
    <i className="bx bx-phone-call"></i>
    <a href={`tel:${COMPANY_DETAILS.CONTACT_INFO.PRIMARY_PHONE_NUMBER}`}>
      {COMPANY_DETAILS.CONTACT_INFO.PRIMARY_PHONE_NUMBER}
    </a>
  </li>
)

const CompanyAddressContact = () => (
  <li>
    <i className="bx bx-map"></i>
    {COMPANY_DETAILS.HQ.FULL_ADDRESS}
  </li>
);

export const CompanyContactItems = ({
  prop,
}) => {
  return (
    <></>
  );
}

CompanyContactItems.CompanyMailItem = CompanyMailItem;
CompanyContactItems.CompanyPhoneContact = CompanyPhoneContact;
CompanyContactItems.CompanyAddressContact = CompanyAddressContact;

CompanyContactItems.propTypes = {
  prop: null,
}