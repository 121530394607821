import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import cx from "classnames"
import _ from "lodash"
import WhiteLogo from "../../assets/images/white-logo.png"
import Shape16 from "../../assets/images/shape/shape16.png"

import { SocialLinks } from "../SocialLinks/SocialLinks"
import { ObjectUtils } from "../../utils/objectUtils"
import { CompanyContactItems } from "../CompanyContactItems/CompanyContactItems"

import { COMPANY_NAME } from "../../config/common"
import { COMPANY_DETAILS } from "../../config"

const FOOTER_CONFIG = {
  columns: [
    {
      heading: "Explore",
      links: [
        { label: "Home" },
        { label: "About" },
        { label: "Pricing" },
        { label: "Portfolio" },
        { label: "Contact" }
      ]
    },
    {
      heading: "Resources",
      links: [
        { label: "Team" },
        { label: "Services" },
        { label: "FAQ" },
        { label: "Blog" }
      ]
    }
  ]
}

const FooterColumn = ({ column, className }) => (
  <div className={cx("col-lg-2 col-md-6 col-sm-6 d-flex", className)}>
    <div className="single-footer-widget pl-5">
      <h3>{column.heading}</h3>
      <ul className="footer-links-list">
        {column.links.map((link) => {
          return (
            <li>
              <Link to={"/" + link.label.toLowerCase()}>{link.label}</Link>
            </li>
          )
        })}
      </ul>
    </div>
  </div>
)

FooterColumn.propTypes = {
  column: PropTypes.object.isRequired,
  className: PropTypes.string
}

const Footer = ({ variant = null }) => {
  const currentYear = new Date().getFullYear()

  return (
    <footer className="footer-area">
      <div className="container">
        <div className="row">
          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              {variant?.displayCompanyLogo && (
                <Link to="/" className="logo">
                  <h2 className="text-white">{COMPANY_NAME}</h2>
                  {/*<img src={WhiteLogo} alt="logo" />*/}
                </Link>
              )}
              <p>{variant?.logoSubheading}</p>
              <SocialLinks
                socialLinks={COMPANY_DETAILS.SOCIAL_ACCOUNTS_URL}
              />
            </div>
          </div>

          {FOOTER_CONFIG.columns.map((col) => {
            return (
              <FooterColumn column={col} />
            )
          })}

          <div className="col-lg-4 col-md-6 col-sm-6">
            <div className="single-footer-widget">
              <h3>Get in touch!</h3>

              <ul className="footer-contact-info">
                <CompanyContactItems.CompanyAddressContact />
                <CompanyContactItems.CompanyPhoneContact />
                <CompanyContactItems.CompanyMailItem />
              </ul>
            </div>
          </div>
        </div>

        <div className="footer-bottom-area mt-sm-0">
          <div className="row align-items-center">
            <div className="col-lg-6 col-md-6">
              <p>
                <i className="bx bx-copyright"></i>{currentYear} <strong>{COMPANY_NAME}</strong>. All Rights Reserved.
              </p>
            </div>

            <div className="col-lg-6 col-md-6">
              <ul>
                <li>
                  <Link to="/privacy-policy">Privacy Policy</Link>
                </li>
                <li>
                  <Link to="/terms-of-service">Terms & Conditions</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div className="shape16">
        <img src={Shape16} alt="Shape" />
      </div>
    </footer>
  )
}

export default Footer
