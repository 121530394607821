import React from 'react';
import { Link } from "gatsby"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCoffee,
  faAddressBook,
  faAirFreshener,
  faAmbulance,
  faAtom,
  faBus,
  faCoins,
  faDice,
} from '@fortawesome/free-solid-svg-icons';

import { FEATURE_CARDS_CONFIG } from "./config/featureCards"

import { SECTION_IDS } from "./config/sections"
import { COMPANY_NAME, CONTENT_TYPE, FULL_DOMAIN } from "./config/common"
import { ItemCreator } from "./utils/itemCreator"
import { ICON_LIBRARIES, FLATICON_ICONS } from "./config/icons"
import { PLAN_NAMES, PLANS } from "./config/plans"
import { ObjectUtils } from "./utils/objectUtils"
import OKTA_LOGO from "./assets/images/logos/okta_logo_002.svg"
import SLACK_LOGO from "./assets/images/logos/slack_logo_003.svg"
import SALESFORCE_LOGO from "./assets/images/logos/salesforce_logo_001.svg"
import INTERCOM_LOGO from "./assets/images/logos/intercom_logo_002.svg"
import ZENDESK_LOGO from "./assets/images/logos/zendesk_logo_002.svg"
import IVANTI_LOGO from "./assets/images/logos/ivanti_logo_001.svg"
import BIGCOMMERCE_LOGO from "./assets/images/logos/bigcommerce_logo_001.svg"
import ATLASSIAN_LOGO from "./assets/images/logos/atlassian_logo_001.svg"
import JIRA_LOGO_1 from "./assets/images/logos/jira_logo_001.svg"
import MAGENTO_LOGO from "./assets/images/logos/magento_logo_001.svg"
import SERVICENOW_LOGO from "./assets/images/logos/servicenow_logo_001.svg"
import SHOPIFY_LOGO from "./assets/images/logos/shopify_logo_001.svg"
import FRESHDESK_LOGO from "./assets/images/logos/freshdesk_logo_001.svg"

export const GLOBAL_CONFIG = {
  TEMPLATE_ID: 3,
}

export const PAGES = {
  PRIVACY_AND_SECURITY: 'PRIVACY_AND_SECURITY',
  DOCUMENTATION: 'DOCUMENTATION',
  PRICING: 'PRICING',
  HOME: '/',
  FEATURES: 'FEATURES',
  ROI_CALCULATOR: 'ROI_CALCULATOR',
  FAQ: 'FAQ',
}

export const BANNER_3_CONTENT_VARIANTS = {
  ONE: {
    // heading: `Help your users instantaneously`,
    heading: `Help your users instantly`,
    // heading: `Leave your users in awe`,
    // heading: `Solve your user problems in a breeze`,
    // heading: `Take your customer service to the next level`,
    // heading: `Win your customers and their trust with exemplary support`,
    // subheading: [
    //   'Take your customer support to the next level by being able to see and control their screen in real-time to solve issues immediately.',
    //   'nn',
    //   'No more wasteful Zoom calls with endless misunderstandings.',
    // ],
    subheading: `
      Take your customer support to the next level by being able to see and control their screen in real-time to solve issues immediately.
      <br />
      No more wasteful Zoom calls with endless misunderstandings.
    `,
    primaryButtonLabel: `See how it works`,
    scrollToOnClick: `${SECTION_IDS.HOW_IT_WORKS}`,
    // primaryButtonLabel: `Get started for free`,
    secondaryButtonLabel: `Request a demo`,
  },
}

export const BANNER_3_LAYOUT_VARIANTS = {
  ONE: {
    hideBannerImageOnMobile: false,
    ctaButtonsSingleRow: true,
    requestDemoButton: {
      switchTextAndIcon: true,
    }
  },
  TWO: {
    hideBannerImageOnMobile: false,
    ctaButtonsSingleRow: true,
    requestDemoButton: {
      switchTextAndIcon: false
    }
  }
}

export const BANNER_VARIANTS = {
  ONE: {
    text: BANNER_3_CONTENT_VARIANTS.ONE,
    layout: BANNER_3_LAYOUT_VARIANTS.ONE
  },
  TWO: {
    text: BANNER_3_CONTENT_VARIANTS.TWO,
    layout: BANNER_3_LAYOUT_VARIANTS.TWO
  }
}

export const USE_CASES_VARIANTS = {
  ONE: {
    heading: 'Suitable for many use-cases',
    subheading: `${COMPANY_NAME} can easily adapt your use-case and company workflow.`,
    subtitle: null,
    content: {
      type: CONTENT_TYPE.CARDS,
      items: FEATURE_CARDS_CONFIG.USE_CASES
    }
  },
}

export const HOW_IT_WORKS_ITEMS = {
  ONE: [
    ItemCreator.howItWorksItem({
      // heading: 'Pick a user',
      heading: 'Pick a user from the sidebar',
      faIcon: <FontAwesomeIcon icon={faCoffee} size="1x" />,
      subheading: `Easily see who's online in real-time and find the user that needs help in seconds.`,
    }),
    ItemCreator.howItWorksItem({
      heading: 'See the situation',
      faIcon: <FontAwesomeIcon icon={faAddressBook} size="2x" />,
      subheading: `Inspect the users' screen in real-time; with no setup required.`,
    }),
    ItemCreator.howItWorksItem({
      heading: 'Tackle the issue immediately',
      faIcon: <FontAwesomeIcon icon={faCoins} size="2x" />,
      subheading: `Request control of the user's screen and help them to solve the problem and explain the situation. All within just a couple of clicks.`,
    }),
    ItemCreator.howItWorksItem({
      heading: 'Yet another happy customer',
      faIcon: <FontAwesomeIcon icon={faAtom} size="2x" />,
      subheading: `
        Customers value amazing support now more than ever.
        Stand out from the competition by offering more than the standard live-chat or email support.
      `,
    }),
    ItemCreator.howItWorksItem({
      heading: 'Rinse and repeat',
      // heading: 'Rinse and repeat!',
      faIcon: <FontAwesomeIcon icon={faAtom} size="2x" />,
      subheading: `
        Your company is here to make a difference - start with exceptional CX
        and your customers will remember your efforts forever.
      `,
    }),
  ]
}

export const BENEFITS_SECTION_ITEMS = {
  ONE: [
    ItemCreator.benefitsCardItem({
      heading: `Unmatched flexibility`,
      subheading: `
        Help your customers immediately - Stickly makes it very easy to see and
        control your customer's screen so you don't need any 3rd party software like Zoom.
      `,
      icon: FLATICON_ICONS.STARTUP,
    }),
    ItemCreator.benefitsCardItem({
      heading: `Your competitive advantage`,
      // heading: `Competitive advantage`,
      // heading: `Exceptional service`,
      // subheading: `
      //   Win your customers' trust from the get-go and keep frustration at a minimum.
      //   Never make your customers leave the site out of frustration again.
      // `,
      subheading: `
        Offer something much better than the competition and leave your customers in awe whenever they use your product.
        Get much better control of your word-of-mouth feedback.
      `,
      icon: FLATICON_ICONS.BAR_CHART,
    }),
    ItemCreator.benefitsCardItem({
      heading: `Actionable analytics`,
      subheading: `
        Make decisions based on the customer behavior data collected and
         turn the data into actionable points to improve your product and make your customers happier than ever.
      `,
      icon: FLATICON_ICONS.DARTS,
    }),
    ItemCreator.benefitsCardItem({
      heading: `Deeper insights into customer behavior and your product`,
      subheading: `
      Get unparalleled context on your services and users, with the ability to step in and help whenever your customers need it.
      \n\n
      Identify UX inefficiencies in your product and address them to make your customers even happier.`,
      icon: FLATICON_ICONS.COMPETITOR,
    }),
    ItemCreator.benefitsCardItem({
      heading: `Privacy and security`,
      subheading: `We take customer privacy and information security very seriously - Stickly allows you to make sure sensitive customer data (such as credit card number etc.) is not visible for anybody.`,
      icon: FLATICON_ICONS.SEARCH,
    }),
  ],
}

export const BENEFITS_SECTION_VARIANTS = {
  ONE: {
    heading: `Take your customer service to the next level`,
    subheading: `Stand out from your competitors by offering amazing customer service with all the features Stickly brings in.`,
    subtitle: null,
    content: {
      type: CONTENT_TYPE.CARDS,
      items: BENEFITS_SECTION_ITEMS.ONE,
    }
  }
}

export const HOW_IT_WORKS_SECTION_VARIANTS = {
  ONE: {
    heading: null,
    subheading: `Stand out from the competition by offering exceptional customer support - ${COMPANY_NAME} makes this extremely simple.`,
    subtitle: null,
    iconLibrary: ICON_LIBRARIES.FONT_AWESOME,
    content: {
      type: CONTENT_TYPE.NUMBERED_LIST_VERTICAL,
      items: HOW_IT_WORKS_ITEMS.ONE
    }
  },
  TWO: {
    heading: null,
    subheading: `Leave your customers in awe with exceptional instant support. ${COMPANY_NAME} makes this super easy.`,
    subtitle: null,
    iconLibrary: null,
    content: {
      type: CONTENT_TYPE.NUMBERED_LIST_VERTICAL,
      items: HOW_IT_WORKS_ITEMS.ONE
    }
  }
}

export const FOOTER_VARIANTS = {
  ONE: {
    displayCompanyLogo: true,
    logoSubheading: `${COMPANY_NAME} helps you take your customer service to the next level. All just a single click away`,
    verticalLists: []
  }
}

export const COMPANY_DETAILS = {
  NAME: 'Obseto',
  NAME_UPPERCASE: 'OBSETO',
  NAME_LOWERCASE: 'obseto',
  // NAME: 'Stickly',
  // NAME_UPPERCASE: 'STICKLY',
  // NAME_LOWERCASE: 'stickly',
  HQ: {
    FULL_ADDRESS: 'Vilnius, Lithuania',
    // FULL_ADDRESS: 'Manchester, United Kingdom',
  },
  CONTACT_INFO: {
    PRIMARY_EMAIL: `hello@${FULL_DOMAIN}`,
    PRIMARY_PHONE_NUMBER: '+37068229866'
  },
  SOCIAL_ACCOUNTS_URL: {
    FACEBOOK: 'https://www.facebook.com/Stickly-111560677679936',
    LINKEDIN: 'https://www.linkedin.com/company/' + COMPANY_NAME,
    INSTAGRAM: null,
    TWITTER: null,
  },
  EMAILS: ObjectUtils.suffixAllValuesWith({
    SALES: 'sales',
    MARKETING: 'marketing',
    HELLO: 'hello',
    DEVTEAM: 'devteam',
  }, `@${FULL_DOMAIN}`),

}

export const OBSETO_COMPANY_DETAILS = {
  NAME: 'Obseto',
  NAME_UPPERCASE: 'OBSETO',
  NAME_LOWERCASE: 'obseto',
  HQ: {
    FULL_ADDRESS: 'Vilnius, Lithuania',
    // FULL_ADDRESS: 'Manchester, United Kingdom',
  },
  CONTACT_INFO: {
    PRIMARY_EMAIL: `hello@${COMPANY_NAME}.com`,
    PRIMARY_PHONE_NUMBER: '+37068229866'
  },
  SOCIAL_ACCOUNTS_URL: {
    FACEBOOK: 'https://www.facebook.com/Obseto-112417770948959',
    LINKEDIN: 'https://www.linkedin.com/company/obseto/',
    INSTAGRAM: null,
    TWITTER: null,
  }
}

export const HEADER_VARIANTS = {
  ONE: {

  }
}

export const PRICING_VARIANTS = {
  ONE: {
    heading: null,
    subheading: null,
    plans: [
      PLANS[PLAN_NAMES.FREE],
      PLANS[PLAN_NAMES.STARTER],
      PLANS[PLAN_NAMES.GROW],
      PLANS[PLAN_NAMES.ENTERPRISE],
    ]
  }
}

export const INTEGRATION_TYPES = {
  INTERCOM: {
    name: "Intercom",
    // logo: "https://reekon.net/wp-content/uploads/2019/12/dddd.png",
    logo: INTERCOM_LOGO,
  },
  ZENDESK: {
    name: "Zendesk",
    logo: ZENDESK_LOGO,
    // logo: "https://reekon.net/wp-content/uploads/2019/12/jjjj.png"
  },
  SERVICENOW: {
    name: "ServiceNow",
    logo: SERVICENOW_LOGO,
    // logo: "https://reekon.net/wp-content/uploads/2019/12/ffff.png"
  },
  SHOPIFY: {
    name: "Shopify",
    logo: SHOPIFY_LOGO,
    // logo: "https://reekon.net/wp-content/uploads/2019/12/aa.png"
  },
  MAGENTO: {
    name: "Magento",
    logo: MAGENTO_LOGO,
    // logo: "https://reekon.net/wp-content/uploads/2019/12/cc.png"
  },
  BIG_COMMERCE: {
    name: "BigCommerce",
    logo: BIGCOMMERCE_LOGO,
    // logo: "https://reekon.net/wp-content/uploads/2021/04/BigCommerce-logo-dark.png"
  },
  SLACK: {
    name: 'Slack',
    logo: SLACK_LOGO,
  },
  SALESFORCE: {
    name: 'Salesforce',
    logo: SALESFORCE_LOGO,
    //logo: SALESFORCE_LOGO,
  },
  FRESHDESK: {
    name: "Freshdesk",
    logo: FRESHDESK_LOGO,
    // logo: "https://reekon.net/wp-content/uploads/2019/12/gggg.png"
  },
  IVANTI: {
    name: "Ivanti",
    logo: IVANTI_LOGO,
    //  logo: "https://reekon.net/wp-content/uploads/2019/12/hhhh.png"
  },
  OKTA: {
    name: "Okta",
    logo: OKTA_LOGO
  },
  JIRA: {
    name: "Atlassian Software",
    logo: ATLASSIAN_LOGO,
    // logo: "https://reekon.net/wp-content/uploads/2019/12/iiii.png"
  }
}

export const SOCIAL_LINKS_CONFIG = {

}

