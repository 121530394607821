import { withHttpsProtocol } from "../utils/http"
import { ObjectUtils } from "../utils/objectUtils"

const DOMAIN_EXT = 'com';
export const COMPANY_NAME = 'Obseto'

export const FULL_DOMAIN = `${COMPANY_NAME.toLowerCase()}.${DOMAIN_EXT}`;

export const CONTENT_TYPE = {
  CARDS: 'cards',
  TABS: 'tabs',
  SLIDER: 'slider',
  NUMBERED_LIST_VERTICAL: 'num-list-v'
}

export const PLATFORM_BASE_URL = withHttpsProtocol(`app.${FULL_DOMAIN}`)
