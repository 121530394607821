import React from 'react';
import PropTypes from "prop-types"
import cx from 'classnames';
import _ from 'lodash';
import { Button } from "./Button/Button"

export const LinkButton = ({
                             label,
  link,
}) => {

  const renderCnt = () => {
    return (
      <>
        <i className="flaticon-play-button mr-3" />
        <span>{label}</span>
      </>
    );
  }
  return (
    <Button
      openInNewTab
      withBtnBoxWrapper={false}
      isLink
      isExternalLink={true}
      link={link}
      types={["primary", "transparent"]}
    >
      {renderCnt()}
    </Button>
  );
}

LinkButton.propTypes = {
  label: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
}