import React from 'react';
import { Link } from "gatsby"
import { COMPANY_NAME } from "../config/common"
import logoAsset from "../assets/images/logo.png"

export const Logo = ({ onToggle }) => {
  return (
    <Link to="/" onClick={onToggle} className="navbar-brand">
      <h1>{COMPANY_NAME}</h1>
      {/*<img src={logoAsset} alt="logo" />*/}
    </Link>
  )
}
