import React from 'react';
import {
  faCoffee,
  faAddressBook,
  faAirFreshener,
  faAmbulance,
  faAtom,
  faBus,
  faCoins,
  faDice,
  faRocket,
  faInfo,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export const ICON_LIBRARIES = {
  FONT_AWESOME: 'fa',
  FLATICON: 'flaticon',
  BOX_ICONS: 'boxicons'
}

export const FLATICON_ICONS = {
  EMAIL_MARKETING: 'flaticon-email-marketing',
  SPEED: 'flaticon-speed',
  RESEARCH: 'flaticon-research',
  COMPETITOR: 'flaticon-competitor',
  PPC: 'flaticon-ppc',
  CONTENT_MANAGEMENT: 'flaticon-content-management',
  KEYWORDS: 'flaticon-keywords',
  MEGAPHONE: 'flaticon-megaphone',
  STARTUP: 'flaticon-startup',
  NEXT_BUTTON: 'flaticon-next-button',
  BACK_ARROW: 'flaticon-back-arrow',
  PLUS: 'flaticon-plus',
  MINUS: 'flaticon-minus',
  CHEVRON: 'flaticon-chevron',
  BACK: 'flaticon-back',
  MENU: 'flaticon-menu',
  SEARCH: 'flaticon-search',
  SHOPPING_CART: 'flaticon-shopping-cart',
  DOWN_ARROW: 'flaticon-down-arrow',
  MENU_1: 'flaticon-menu-1',
  WEB_SETTINGS: 'flaticon-web-settings',
  CONVERSATION: 'flaticon-conversation',
  SAXOPHONE: 'flaticon-saxophone',
  QUOTE: 'flaticon-quote',
  QUOTE_1: 'flaticon-quote-1',
  JIGSAW: 'flaticon-jigsaw',
  PAPER_PLANE: 'flaticon-paper-plane',
  DIAMOND: 'flaticon-diamond',
  SHARE_ICON: 'flaticon-share-icon',
  CONTENT: 'flaticon-content',
  DARTS: 'flaticon-darts',
  BAR_CHART: 'flaticon-bar-chart',
  LINK: 'flaticon-link',
  REPURATION: 'flaticon-reputation',
}

// <FontAwesomeIcon icon={faCoffee} size="1x" />
// <FontAwesomeIcon icon={faAddressBook} size="2x" />
// <FontAwesomeIcon icon={faAirFreshener} size="3x" />
// <FontAwesomeIcon icon={faAtom} size="4x" />
// <FontAwesomeIcon icon={faAmbulance} size="5x" />
// <FontAwesomeIcon icon={faBus} size="6x" />
// <FontAwesomeIcon icon={faCoins} size="7x" />
// <FontAwesomeIcon icon={faDice} size="8x" />

const renderSize = (size) => `${size}x`;

export const FONT_AWESOME_ICONS = {
  ROCKET: (size = 2) => <FontAwesomeIcon icon={faDice} size={renderSize(size)} />,
  INFO: (size = 2) => <FontAwesomeIcon icon={faInfo} size={renderSize(size)} />,
  INFO_CIRCLE: (size = 2) => <FontAwesomeIcon icon={faInfoCircle} size={renderSize(size)} />,
}