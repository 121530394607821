import { ItemCreator } from "../utils/itemCreator"
import { COMPANY_NAME } from "./common"

export const PLAN_DURATION_TYPE = {
  YEARLY: 'year',
  MONTHLY: 'month'
}

export const PLAN_NAMES = {
  FREE: 'FREE',
  GROW: 'GROW',
  STARTER: 'STARTER',
  ENTERPRISE: 'ENTERPRISE',
}

export const PLAN_FEATURES = {
  VIEW_AND_CONTROL_LIMITED_SESSIONS: ItemCreator.planFeature(
    (num = 5) => `
      Control and view max ${num} sessions per day
    `,
    null,
    null,
  ),
  VIEW_BROWSER_CONSOLE: ItemCreator.planFeature(
    () => `Be able to see browser console to identify issues easier`,
    null,
    `See your customers' browser errors and logs directly on the dashboard, in real-time.`
  ),
  PEEK_AND_CONTROL_UNLIMITED_USER_SESSIONS: ItemCreator.planFeature(
    () => `See and control unlimited user sessions`,
    null,
    null,
  ),
  SLACK_INTEGRATION: ItemCreator.planFeature(
    () => `Slack alerts`,
    null,
    `
      Receive notifications for specific customers in your Slack.
      Limit notifications per each customers and configure other options.
    `
  ),
  TEAM_INVITES: ItemCreator.planFeature(
    () => `Team management - you can invite your team members, assign roles`,
    null,
    null,
  ),
  // alt name: ADMINISTRATION_FUNCTIONALITY
  USER_ROLES: ItemCreator.planFeature(
    () => `Dashboard user roles`,
    null,
    `
      Grant specific users the administrator role that
      will allow to change to modify membership, billing, privacy and security settings.
    `,
  ),
  ADVANCED_END_USER_PRIVACY_PERMISSIONS: ItemCreator.planFeature(
    () => `Advanced Privacy Options`,
    null,
    `
      Turn on/off privacy features for certain dashboard users\
      such as permissions to guide-only and view-only.
    `,
  ),
  ZENDESK_INTEGRATION: ItemCreator.planFeature(
    () => `Zendesk Integration`,
    null,
    `
      See various data (sessions and other) from ${COMPANY_NAME} integrated directly into your tickets on Zendesk dashboard.
    `,
  ),
  INTERCOM_INTEGRATION: ItemCreator.planFeature(
    () => `Intercom Integration`,
    null,
    `See your customers' sessions from ${COMPANY_NAME} attached to your conversations on Intercom`,
    true,
  ),
  SALESFORCE_INTEGRATION: ItemCreator.planFeature(
    () => `Salesforce Integration`,
    null,
    `
      See analytics data from ${COMPANY_NAME} on your CRM dashboard, associated with correct customer card.
    `,
    true,
  ),
  SERVICENOW_INTEGRATION: ItemCreator.planFeature(
    () => `ServiceNow Integration`,
    null,
    `
      See analytics data from ${COMPANY_NAME} integrated directly into ServiceNow tickets and customer profiles.
      The data is updated in real-time.
    `,
  ),
  AUDIT_LOGS: ItemCreator.planFeature(
    () => `Logs for Audit`,
    null,
    `
      Comfortably observe every little change in ${COMPANY_NAME} - be it a view, settings change, remote control initiation and much more. 
    `,
  ),
  DEDICATED_CUSTOMER_SUCCESS_MANAGER: ItemCreator.planFeature(
    () => `A Dedicated Customer Success Expert (free of charge)`,
    // () => `A Dedicated CX Expert (free of charge)`,
    // () => `Dedicated CX Expert (no addt'l charges)`,
    null,
    `
       Access the knowledge of a Dedicated Customer Success Expert that will analyze your situation,
       provide clear suggestions and guidance on how improve CX and general customer satisfaction accordingly.
    `,
  ),
  MULTILANGUAGE_SUPPORT: ItemCreator.planFeature(
    () => `Multilanguage support`,
    null,
    `
      Allow your employees to change to a language of their preferences, thus, removing any language barriers completely.
    `,
  ),
  IDENTITY_MANAGEMENT: ItemCreator.planFeature(
    () => `Manage Identity (SAML 2 SSO Integration)`,
    null,
    `
      Connect your SSO of choice to ${COMPANY_NAME} platform and enjoy the ease of use that it brings to your employees.
    `,
  ),
  PRIORITY_SLA: ItemCreator.planFeature(
    () => `Priority SLA`,
    null,
    `
      Get priority SLA to solve issues ASAP whenever they arise.
    `,
  ),
  ON_PREMISES_HOSTING: ItemCreator.planFeature(
    () => `On-premises hosting & labeling`,
    null,
    `
      Add your branding to ${COMPANY_NAME} platform and host the platform under your network
      for maximum security of your company's data.
    `,
  ),
}

export const HIGHLIGHTED_PLAN = PLAN_NAMES.GROW;

export const PLANS = {
  [PLAN_NAMES.FREE]: {
    name: 'Free',
    subheading: `
      Try out all the necessary features
      to evaluate if ${COMPANY_NAME} is a great fit.
      We're eager for your feedback and requests!
    `,
    // subheading: `.`,
    pricePerSeatPerMonth: null,
    minSeats: null,
    restrictions: {
      maxSeats: 2,
      maxWebsites: 2,
      maxSessionsPerDay: 6,
    },
    features: [
      // PLAN_FEATURES.VIEW_AND_CONTROL_LIMITED_SESSIONS,
      PLAN_FEATURES.VIEW_BROWSER_CONSOLE,
    ],
  },
  [PLAN_NAMES.STARTER]: {
    name: 'Startup',
    subheading: `
    Perfect for startups aiming to provide exceptional support.
      Collaborate with your team to deliver an awesome CX!
     `,
    // subheading: ``
    pricePerSeatPerMonth: 39,
    minSeats: null,
    yearlyDiscountPct: 10,
    features: [
      PLAN_FEATURES.PEEK_AND_CONTROL_UNLIMITED_USER_SESSIONS,
      PLAN_FEATURES.SLACK_INTEGRATION,
      PLAN_FEATURES.TEAM_INVITES,
    ]
  },
  [PLAN_NAMES.GROW]: {
    name: 'Moonshot',
    // name: 'To the moon',
    // name: 'Grow',
    // subheading: `
    //   A great choice for scaling companies that aim to deliver the best CX possible
    //   with a plethora of integrations and with the help from a dedicated CX consultant (no additional charges).
    //   Stand out from the competition and leave your customers in awe.
    // `,
    subheading: `
      For growing companies that aim to provide exceptional CX
      (a dedicated CX consultant incl.).
    `,
    pricePerSeatPerMonth: 29,
    minSeats: 3,
    yearlyDiscountPct: 10,
    features: [
      PLAN_FEATURES.USER_ROLES,
      PLAN_FEATURES.DEDICATED_CUSTOMER_SUCCESS_MANAGER,
      PLAN_FEATURES.ADVANCED_END_USER_PRIVACY_PERMISSIONS,
      PLAN_FEATURES.ZENDESK_INTEGRATION,
      PLAN_FEATURES.INTERCOM_INTEGRATION,
      PLAN_FEATURES.SALESFORCE_INTEGRATION,
      PLAN_FEATURES.SERVICENOW_INTEGRATION, // todo: move SNOW integration to ENTERPRISE?
    ]
  },
  [PLAN_NAMES.ENTERPRISE]: {
    name: 'Enterprise',
    // subheading: `
    //   Highly-scalable and flexible solution for your organization.
    //   On-premises hosting, SSO, custom branding and much more!
    // `,
    subheading: `
      Highly-scalable and flexible solution for your organization.
      On-premises hosting, custom branding and much more!
    `,
    minSeats: null,
    yearlyDiscountPct: 10,
    pricePerSeatPerMonth: 'CUSTOM',
    features: [
      PLAN_FEATURES.ON_PREMISES_HOSTING,
      PLAN_FEATURES.MULTILANGUAGE_SUPPORT,
      PLAN_FEATURES.IDENTITY_MANAGEMENT,
      PLAN_FEATURES.PRIORITY_SLA,
      PLAN_FEATURES.AUDIT_LOGS,
    ],
  }
}