import React, { useEffect } from "react"
import GoTop from "./GoTop"
import SEO from "./SEO"
import { NavbarStyleThree } from "./NavbarStyleThree"
import { FOOTER_VARIANTS } from "../../config"
import Footer from "./Footer"
import { AnimatePresence, motion } from "framer-motion"
import { getWindowLocationHash, scrollToElement } from "../../utils/common"

const ANIMATE_PAGE_TRANSITIONS = true

const PAGE_TRANSITION_ANIMATION_DURATION = 0.3

const animationVariants = {
  initial: {
    opacity: 0
  },
  enter: {
    opacity: 1,
    transition: {
      duration: PAGE_TRANSITION_ANIMATION_DURATION,
      delay: PAGE_TRANSITION_ANIMATION_DURATION,
      when: "beforeChildren"
    }
  },
  exit: {
    opacity: 0,
    transition: { duration: PAGE_TRANSITION_ANIMATION_DURATION }
  }
}

const AnimatedPageContentWrapper = ({ children, location }) => (
  ANIMATE_PAGE_TRANSITIONS
    ? (
      <AnimatePresence>
        <motion.main
          key={location?.pathname}
          variants={animationVariants}
          initial="initial"
          animate="enter"
          exit="exit"
        >
          {children}
        </motion.main>
      </AnimatePresence>
    )
    : children
);

const Layout = ({ children, seoTitle, location }) => {
  console.log("location.pathname", location?.pathname)
  useEffect(
    () => {
      console.log('page loaded, check hash')
      const hash = getWindowLocationHash();
      console.log({ hash,  })
      if (hash) {
        const targetElement = hash;
        const el = document.getElementById(targetElement);
        console.log('el', el)
        if (el) {
          console.log('scrollin...')
          setTimeout(() => {
            scrollToElement(targetElement);
          }, 500);
        } else {
          console.error('Element to scroll to not found; searched for: ', targetElement);
        }
        // if ()
      }

      return () => {};
    },
    []
  );
  return (
    <>
      <SEO title={seoTitle} />
      {/*<Footer variant={FOOTER_VARIANTS.ONE} />*/}
      <NavbarStyleThree />
      <AnimatedPageContentWrapper location={location}>
        {children}
      </AnimatedPageContentWrapper>
      <Footer variant={FOOTER_VARIANTS.ONE} />
      <GoTop />
    </>
  )
}
export default Layout
