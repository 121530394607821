import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import cx from "classnames"
import noop from "lodash/noop"

import { HomeNavItem } from "../HomeNavItem"

import { Logo } from "../Logo"
import { ContactNavBarItem } from "../NavBarItems/ContactNavBarItem"
import { BlogNavbarItem } from "../NavBarItems/BlogNavbarItem"
import { PortfolioNavbarItem } from "../NavBarItems/PortfolioNavbarItem"
import { PagesNavbarItem } from "../NavBarItems/PagesNavbarItem"
import { ServicesNavbarItem } from "../NavBarItems/ServicesNavbarItem"
import { AboutNavbarItem } from "../NavBarItems/AboutNavbarItem"
import { SearchAndSidebarToggle } from "../Navbar/SearchAndSidebarToggle"
import { formatCalendlyUrl, formatPageUrl, scrollToElement, getFirstLevelPageName } from "../../utils/common"
import { PAGES } from "../../config"

import { FULL_DOMAIN } from "../../config/common"
import { SECTION_IDS } from "../../config/sections"
import "./Navbar.scss"
import { LinkButton } from "../LinkButton"
import AniLink from "gatsby-plugin-transition-link/AniLink";

const NAVBAR_ITEM_TYPES = {
  LINK_TO_SECTION: "LINK_TO_SECTION",
  EXTERNAL_LINK: "EXTERNAL_LINK",
  INTERNAL_LINK: "INTERNAL_LINK",
  DROPDOWN_LIST: "DROPDOWN_LIST",
  MAIL_TO_LINK: "MAIL_TO_LINK",
  EXTERNAL_LINK_BTN: "EXTERNAL_LINK_BTN"
}

const HEADER_ITEMS = {
  HOW_IT_WORKS: {
    type: NAVBAR_ITEM_TYPES.LINK_TO_SECTION,
    value: SECTION_IDS.HOW_IT_WORKS,
    label: "How it works",
    location: PAGES.HOME,
    onClick: scrollToElement
  },
  GET_A_DEMO: {
    type: NAVBAR_ITEM_TYPES.EXTERNAL_LINK,
    value: formatCalendlyUrl()
  },
  EMAIL_US: {
    type: NAVBAR_ITEM_TYPES.MAIL_TO_LINK,
    value: `mailto:${FULL_DOMAIN}`
  },
  PRIVACY_AND_SECURITY: {
    label: "Privacy & Security",
    type: NAVBAR_ITEM_TYPES.INTERNAL_LINK,
    value: PAGES.PRIVACY_AND_SECURITY
  },
  DOCS: {
    label: "Docs",
    type: NAVBAR_ITEM_TYPES.EXTERNAL_LINK,
    value: `https://docs.${FULL_DOMAIN}`
  },
  PRICING: {
    label: "Pricing",
    type: NAVBAR_ITEM_TYPES.INTERNAL_LINK,
    value: PAGES.PRICING
  },
  FEATURES: {
    label: "Features",
    type: NAVBAR_ITEM_TYPES.INTERNAL_LINK,
    value: PAGES.FEATURES
  },
  ROI_CALCULATOR: {
    label: "ROI Calculator",
    type: NAVBAR_ITEM_TYPES.INTERNAL_LINK,
    value: PAGES.ROI_CALCULATOR
  },
  FAQ: {
    label: "FAQ",
    type: NAVBAR_ITEM_TYPES.INTERNAL_LINK,
    value: PAGES.FAQ
  },
  SUPPORT: {
    label: "Support",
    type: NAVBAR_ITEM_TYPES.DROPDOWN_LIST,
    // value: PAGES.FAQ,
    items: [
      {
        link: "/terms-of-service",
        label: "Terms of service",
        onClick: noop
      },
      {
        link: "/privacy-policy",
        label: "Privacy policy",
        onClick: noop
      },
      {
        link: "/cookie-policy",
        label: "Cookie policy",
        onClick: noop
      }
    ]
  },
  INTEGRATIONS: {
    label: "Integrations",
    type: NAVBAR_ITEM_TYPES.LINK_TO_SECTION,
    value: SECTION_IDS.INTEGRATIONS,
    location: PAGES.HOME,
    onClick: scrollToElement
  },
  LOGIN: {
    label: (
      <div style={{ position: "relative", top: -3 }}>
        Login <i className="ml-2 flaticon-play-button" />
      </div>
    ),
    type: NAVBAR_ITEM_TYPES.EXTERNAL_LINK,
    value: `https://app.${FULL_DOMAIN}`
  }
}

const HEADER_CONFIG = {
  items: [
    // HEADER_ITEMS.FEATURES,
    // HEADER_ITEMS.DOCS,
    // HEADER_ITEMS.PRIVACY_AND_SECURITY,
    HEADER_ITEMS.SUPPORT,
    HEADER_ITEMS.INTEGRATIONS,
    HEADER_ITEMS.FAQ,
    HEADER_ITEMS.PRICING,
    HEADER_ITEMS.HOW_IT_WORKS,
    HEADER_ITEMS.LOGIN
  ]
}

const NavItem = ({ children, className }) => (
  <li className={cx("nav-item", className)}>
    {children}
  </li>
)

const ExpandedMenuItems = ({ items }) => (
  <ul className="dropdown-menu">
    {
      items.map((item) => (
        <li className="nav-item">
          <Link to={item.link} activeClassName="active" onClick={item.onClick} className="nav-link">
            {item.label}
          </Link>
        </li>
      ))
    }
  </ul>
)

ExpandedMenuItems.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object).isRequired
}

export const NavbarStyleThree = ({}) => {
  const [menu, setMenu] = React.useState(true)

  const toggleNavbar = () => {
    setMenu(!menu)
  }

  const NAVBAR_ID = "test-navbar"

  React.useEffect(() => {
    const STICK_HEADER_WHEN_SCROLLED_PAST = 170
    const STICKLY_CLASS = "fixed-top"
    // const STICKLY_CLASS = 'is-sticky';
    let elementId = document.getElementById(NAVBAR_ID)
    const trackForSticky = () => {
      if (window.scrollY > STICK_HEADER_WHEN_SCROLLED_PAST) {
        elementId.classList.add(STICKLY_CLASS)
      } else {
        elementId.classList.remove(STICKLY_CLASS)
      }
    }

    document.addEventListener("scroll", trackForSticky)
    // window.scrollTo(0, 0);

    return () => window.removeEventListener("scroll", trackForSticky)
  }, [])

  const classOne = menu ? "collapse navbar-collapse" : "collapse navbar-collapse show"
  const classTwo = menu ? "navbar-toggler navbar-toggler-right collapsed" : "navbar-toggler navbar-toggler-right"

  const USE_BOOTSTRAP_NAVBAR = false

  const renderHeaderItems = () => {
    return HEADER_CONFIG.items.map((item, index) => {
      let children = null
      if (item.type === NAVBAR_ITEM_TYPES.INTERNAL_LINK) {
        const toLocation = "/" + formatPageUrl(item.value);
        children = (
          <>
            {/*WORKS, but buggy as fuck <AniLink swipe direction="up" to={toLocation} activeClassName="active" className="nav-link" duration={1}>*/}
            {/*  {item.label + ' (ani)'}*/}
            {/*</AniLink>*/}
            {/*<AniLink fade to={toLocation} activeClassName="active" className="nav-link" duration={1}>*/}
            {/*  {item.label + ' (ani)'}*/}
            {/*</AniLink>*/}
            <Link to={toLocation} activeClassName="active" className="nav-link">
              {item.label}
            </Link>
          </>
        )
      }
      if (item.type === NAVBAR_ITEM_TYPES.EXTERNAL_LINK) {
        children = (
          <a className="nav-link" href={item.value} target={"_blank"} rel={"noreferrer"}>
            {item.label}
          </a>
        )
      }
      if (item.type === NAVBAR_ITEM_TYPES.MAIL_TO_LINK) {
        children = (
          <a className="nav-link" href={item.value} rel={"noreferrer"}>{item.label}</a>
        )
      }
      if (item.type === NAVBAR_ITEM_TYPES.LINK_TO_SECTION) {
        children = (
          <a className="nav-link" onClick={() => {
            item.onClick(item.value, item.location)
          }}>{item.label}</a>
        )
      }
      if (item.type === NAVBAR_ITEM_TYPES.EXTERNAL_LINK_BTN) {
        children = (
          <LinkButton label={item.label} link={item.value} />
        )
      }
      if (item.type === NAVBAR_ITEM_TYPES.DROPDOWN_LIST) {
        children = (
          <li className="nav-item">
            <Link to="/#" onClick={e => e.preventDefault()} className="nav-link">
              {item.label} <i className="bx bx-chevron-down animated"></i>
            </Link>
            <ExpandedMenuItems items={item.items} />
          </li>
        )
      }
      return (
        <NavItem key={index}>{children}</NavItem>
      )
    })
  }

  // if (USE_BOOTSTRAP_NAVBAR) {
  //   return (
  //     <nav id={NAVBAR_ID} className={
  //       cx("navbar navbar-light bg-light", { "fixed-top": true })
  //     }>
  //       <Logo onToggle={toggleNavbar} />
  //       <button
  //         onClick={toggleNavbar}
  //         className={classTwo}
  //         type="button"
  //         data-toggle="collapse"
  //         data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
  //         aria-expanded="false"
  //         aria-label="Toggle navigation"
  //       >
  //         <span className="icon-bar top-bar"></span>
  //         <span className="icon-bar middle-bar"></span>
  //         <span className="icon-bar bottom-bar"></span>
  //       </button>
  //
  //       <div className={classOne} id="navbarSupportedContent">
  //         <ul className="navbar-nav">
  //           <HomeNavItem
  //             onNavbarToggle={toggleNavbar}
  //             show={true}
  //           />
  //           <AboutNavbarItem onToggle={toggleNavbar} />
  //           <ServicesNavbarItem onToggle={toggleNavbar} />
  //           <PagesNavbarItem onToggle={toggleNavbar} />
  //           <PortfolioNavbarItem onToggle={toggleNavbar} />
  //           <BlogNavbarItem onToggle={toggleNavbar} />
  //           <ContactNavBarItem onToggle={toggleNavbar} />
  //         </ul>
  //       </div>
  //     </nav>
  //   )
  // }

  return (
    <React.Fragment>
      <div id={NAVBAR_ID} className={cx(
        "navbar navbar-area navbar-style-two"
        // { 'fixed-top': false }
      )}>
        <div className="main-nav w-100">
          <div className="container">
            <nav className="navbar navbar-expand-lg navbar-light w-100">
              <Logo onToggle={toggleNavbar} />
              <button
                onClick={toggleNavbar}
                className={classTwo}
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="icon-bar top-bar"></span>
                <span className="icon-bar middle-bar"></span>
                <span className="icon-bar bottom-bar"></span>
              </button>

              <div className={classOne} id="navbarSupportedContent">
                <ul className="navbar-nav">
                  {renderHeaderItems()}
                </ul>
              </div>

              {false && <SearchAndSidebarToggle />}
            </nav>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}
