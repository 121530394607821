import moment from "moment"
import queryString from "query-string"
import replace from "lodash/replace"
import { PAGES } from "../config"
import { navigate } from "gatsby"

export const inPixels = (str) => `${str}px`

export const isBrowser = () => typeof window !== "undefined"

export const getWindowInnerWith = () => {
  return isBrowser() ? window.innerWidth < 767 : null
}

export const isMobileViewport = () => getWindowInnerWith() < 767

export const isHomepage = (page) => page === "" || page === PAGES.HOME

export const scrollToElement = async (targetId, elementPage = PAGES.HOME.toLowerCase()) => {
  const currentPage = getFirstLevelPageName()
  const isCurrentPageHome = isHomepage(currentPage)
  // console.log({ targetId, elementPage, currentPage, isCurrentPageHome })
  if (!isCurrentPageHome && elementPage !== currentPage) {
    await navigate("/" + elementPage === "/" ? "" : elementPage + "#" + targetId)
    // debugger;
    // setTimeout(
    //   () => {
    //     document.getElementById(targetId).scrollIntoView({ alignToTop: true, behavior: 'smooth', inline: "nearest" });
    //   },
    //   500
    // )
  } else {
    document.getElementById(targetId).scrollIntoView({ alignToTop: true, behavior: "smooth", inline: "nearest" })
  }
}

export const formatCalendlyUrl = () => {
  const baseDate = new Date()
  const AUTOOPEN_NEAREST_DAY = false
  const params = {
    month: moment(baseDate).format("YYYY-MM"),
    date: moment(baseDate).format("YYYY-MM-DD")
  }
  const str = queryString.stringify(params)
  return `https://calendly.com/dovydas-stepo/stickly-comprehensive-demo-30min${AUTOOPEN_NEAREST_DAY ? "?" + str : ""}`
}

export const formatPageUrl = (url) => (
  (replace(url.toLowerCase(), /_/g, "-"))
)

export const getWindowLocation = () => (
  isBrowser() ? window.location : null
)

export const getWindowLocationPathname = () => (
  isBrowser() ? window.location.pathname : null
)

export const getWindowLocationHash = () => (
  isBrowser() ? window.location.hash.substring(1) : null
)

export const getFirstLevelPageName = () => {
  return isBrowser() ? window.location.pathname.split("/")[1] : null
}

// /**
//  * @example
//  * injectStyleToHead(`
//  body {
//         color: red;
//       }
//  `);
//
//  injectStyleToHead(`
//  body {
//       background: silver;
//     }
//  `);
//  * @param styleString
//  */
// export const injectStyleToHead = (styleString) => {
//   const style = document.createElement("style")
//   style.textContent = styleString
//   document.head.append(style)
// }
//
// const copyValueToClipboard = (text) => {
//   const textField = document.createElement('textarea', );
//   textField.innerText = text;
//   textField.style = {
//     display: 'none', visibility: 'hidden', position: 'fixed', top: '-9999px'
//   }
//   document.body.appendChild(textField);
//   textField.select();
//   document.execCommand('copy');
//   textField.remove();
//   return text;
// }
//
// // a class from Figma UI -> prototype--wrapperFullScale--2bU4j prototype--wrapper--KZ5DX
// // Used for extracting canvas data from Figma
// const extractDataUrlFromCanvas = (id) => {
//   const canvasEl = document.getElementById(id);
//   if (canvasEl) {
//     const dataUrl = canvasEl.toDataURL();
//     if (dataUrl) {
//       copyValueToClipboard(dataUrl);
//       alert('Canvas DataURL copied to clipboard!');
//     }
//   }
// }
//
// extractDataUrlFromCanvas('whoond');
