export class ItemCreator {
  static howItWorksItem({
                          heading,
                          subheading,
                          faIcon = null,
                          icon = null,
                          image = null,
                          video = null
                        }) {
    return {
      heading,
      subheading,
      icon,
      image,
      video,
      faIcon
    }
  }

  static benefitsCardItem({
                            heading,
                            subheading,
                            icon,
                            image = null,
                            video = null,
                            displayAnimatedCircles = false
                          }) {
    return {
      heading,
      subheading,
      icon,
      image,
      video,
      displayAnimatedCircles
    }
  }

  static planFeature(
    labelGetter,
    tooltipId = null,
    tooltipText = null,
    isWip = false,
  ) {
    return {
      label: labelGetter,
      tooltip: tooltipId || tooltipText ? (
        {
          id: tooltipId,
          text: tooltipText
        }
      ) : null,
      isWip,
    }
  }

  static fullPlanFeature(
    name,
    labelGetter,
    tooltipId = null,
    tooltipText = null,
  ) {
    return {
      [name]: {
        ...ItemCreator.planFeature(labelGetter, tooltipId, tooltipText),
      }
    }
  }
}
